@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $white;
	font-family: $reg, Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 1;
	letter-spacing: 0.15px;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

h1,h2,h3,h4,p{
	margin: 0;
}

h1{
	font-size: 56px;
	font-family: $poster;
	@include tablet-up{
		font-size: 130px;
		letter-spacing: 1.29px;
	}
}

h2{
	font-size: 50px;
	font-family: $poster;
	&.heading{
		border-bottom: solid 1px $black;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

p{
	font-size: 15px;
}

.btn{
	display: inline-block;
	text-transform: uppercase;
	font-size: 15px;
	font-family: $semi;
	background-color: transparent;
	@include hover-focus{
		color: $black;
	}
	&.circle{
		border: solid 1px $black;
		border-radius: 100%;
		padding: 15px 20px;
	}
	&.shape{
		background-image: url('../../dist/img/shape.png');
		background-size: contain;
		padding: 35px;
		background-position: center;
		background-repeat: no-repeat;
	}
}

header {
	padding: 20px 0;
	h1{
		text-align: center;
	}
	@include tablet-up{
		padding: 20px 0 0;
		display: none;
		.container{
			@include flex(row,flex-end,center);
			position: relative;
			max-width: 100%;
			h1{
				position: absolute;
				line-height: 1;
				text-align: left;
				top: 0;
				left: 0;
				background-color: red;
				max-width: 50%;
			}
			img{
				max-width: 50%;
				background-color: red;
			}
			
		}
	}
}

.desktop{
	display: none;
	@include tablet-up{
		display: block;
	}
}

.mobile{
	display: block;
	@include tablet-up{
		display: none;
	}
}

main {
	section{

		&.new-track{
			img{
				width: 100%;
				max-width: 420px;
				margin: 0 auto;
			}
			.info{
				max-width: 420px;
				margin: 0 auto;
				padding-top: 20px;
				text-align: right;
				h3{
					font-size: 30px;
					margin: 10px 0;
				}
				.btn{
					margin: 0 auto;
					display: block;
					text-align: center;
					max-width: max-content;
				}
			}
		}

		&.new-album{
			padding-bottom: 20px;
			.container{
				@include flex(row,space-between,initial);
				flex-wrap: wrap;
				max-width: 420px;
				.info{
					text-align: right;
					flex: 1;
					h2{
						margin-top: 5px;
						text-align: left;
					}
					p{
						text-align: left;
					}
				}
				img{
					flex: 1;
					max-width: 123px;
					object-fit: cover;
					height: 180px;
				}
			}
		}

		&.music{
			.container{
				.music-item{
					@include flex(row,space-between,center);
					a{
						width: calc(50% - 10px);
						img{
							width: 100%;
						}
					}
					.info{
						width: calc(50% - 10px);
						p{
							margin-bottom: 5px;
						}
						.title{
							font-family: $bold;
						}
						a{
							font-size: 15px;
							text-decoration: underline;
							font-family: $bold;
						}
					}
					&:nth-child(odd){
						flex-direction: row-reverse;
					}
				}
			}
		}

		&.videos{
			.container{
				.videos-wrapper{
					margin-bottom: 60px;
					.video{
						@include flex(row,space-between,center);
						flex-wrap: wrap;
						padding: 0 10px;
						.cover{
							width: 100%;
							margin-bottom: 10px;
							img{
								height: 455px;
								object-fit: cover;
							}
						}
						a{
							font-size: 15px;
							text-decoration: underline;
						}
					}
				}
			}
		}

		&.tour{
			.container{
				#events{
					margin-bottom: 60px;
					.event{
						padding: 20px 0;
						border-bottom: solid 1px $black;
						line-height: 1.2;
						font-size: 15px;
						a{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	@include tablet-up{
		@include flex(row,space-between,flex-start);
		.right{
			height: 100vh;
			overflow-y: scroll;
			width: 50%;
			&::-webkit-scrollbar {
				-ms-overflow-style: none;  /* IE and Edge */
				scrollbar-width: none;  /* Firefox */
				display: none;
			}
		}

		.left{
			width: 50%;
			position: relative;
			.logo{
				position: absolute;
				left: 15px;
				width: calc(100% - 30px);
				z-index: 99;
			}
			section.videos{
				position: relative;
				height: calc(100vh - 60.8px);
				overflow-y: scroll;
				&::-webkit-scrollbar {
					-ms-overflow-style: none;  /* IE and Edge */
					scrollbar-width: none;  /* Firefox */
					display: none;
				}
				.videos-wrapper{
					padding-top: 80px;
					margin-bottom: 0;
					.video{
						padding: 0;
						a.cover{
							cursor: url('../../dist/img/video-hover.png'), auto;
							img{
								width: 100%;
								height: initial;
								@include hover-focus{
								}
							}
						}
					}
				}
			}
			footer{
				.container{
					@include flex(row-reverse,space-between,center);
					.copyright{
						flex: 1;
						padding-right: 40px;
						p{
							text-align: left;
							font-size: 10px;
						}
					}
					.social{
						flex: 1;
						max-width: max-content;
					}
				}
			}
		}
		.right{
			section{
				&.new-track{
					.newsletter{
						padding-top: 20px;
						width: 100%;
						max-width: 100%;
						form{
							@include flex(row,initial,center);
						}
						p{
							flex: 2;
							border-bottom: solid 1px $black;
							padding: 14px 0;
							font-size: 15px;
							display: none;
							@media screen and (min-width:1290px) {
								display: block;
							}
						}
						input{
							flex: 1;
							font-size: 15px;
							text-transform: uppercase;
							color: $black;
							&::placeholder{
								color: $black;
							}
						}
						input[type=email]{
							padding: 12px 10px;
							background-color: transparent;
							border: solid 1px transparent;
							border-bottom: solid 1px $black;
							transition: all .4s;
							font-family: $semi;
							@include hover-focus{
								outline: none;
								border: solid 1px $black;
								padding: 10px;
							}
						}
						input[type=submit]{
							max-width: max-content;
							padding: 15px 30px;
							margin-left: 10px;
						}
					}
					.info{
						max-width: 100%;
						@include flex(row, space-between, center);
						.btn{
							margin: 0;
						}
					}
					margin-bottom: 20px;
				}
				
				&.new-album{
					border-bottom: solid 1px $black;
					margin-bottom: 30px;
					.container{
						max-width: 100%;
						@include flex(column-reverse, initial, initial);
						img{
							width: 100%;
							max-width: 100%;
							margin-bottom: 20px;
							height: initial;
						}
						.info{
							@include flex(row, space-between, center);
						}
					}
				}

				&.music{
					margin-bottom: 40px;
					.container{
						.heading{
							margin-bottom: 20px;
						}
						.music-item{
							display: block;
							>a{
								display: none;
							}
							.info{
								@include flex(row,space-between,center);
								width: 100%;
								margin-bottom: 20px;
								p{
									margin: 0;
									flex: 1;
									&:nth-child(1){
										max-width: max-content;
										margin-right: 30px;
									}
									&.title{
										flex: 2;
										padding: 0 10px;
										font-family: $reg;
									}
									&:nth-child(4){
										flex: 2;
										padding: 0 10px;
									}
								}
								a{
									flex: 1;
									max-width: max-content;
								}
							}
						}
					}
				}

				&.tour{
					.container{
						#events{
							border-bottom: solid 1px $black;
							padding-bottom: 30px;
							.event{
								@include flex(row,space-between,center);
								text-align: left;
								border-bottom: none;
								div{
									flex: 1;
									&.event-links{
										max-width: max-content;
									}
									&.event-venue{
										padding: 0 10px;
										flex: 2;
									}
									&.event-location{
										padding: 0 10px;
										flex: 2;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

footer {
	.container{
		.social{
			@include flex (row,initial,center);
			padding: 20px 0;
			a{
				font-size: 20px;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.newsletter{
			margin-bottom: 30px;
			form{
				@include flex(row,initial,initial);
			}
			input{
				flex: 1;
				font-size: 15px;
				text-transform: uppercase;
				color: $black;
				&::placeholder{
					color: $black;
				}
			}
			input[type=email]{
				padding: 10px 0;
				background-color: transparent;
				border: none;
				border-bottom: solid 1px $black;
				transition: all .4s;
				@include hover-focus{
					outline: none;
					border: solid 1px $black;
					padding: 10px;
				}
			}
			input[type=submit]{
				max-width: max-content;
				padding: 15px 30px;
				margin-left: 10px;
			}
		}

		.copyright{
			p{
				font-size: 8px;
				text-align: center;
				line-height: 1.2;
			}
		}
	}
}